import React from 'react';
import { useParams } from "react-router-dom";
import Schedule from './Schedule';
import Standings from './Standings'; // Import the Standings component
import { SportContainer, TitleContainer, SportLandingTitle, SportName, Section } from '../../../App.styled';
import { useScheduler } from '../Basketball/Admin/hooks/useScheduler';

const League = () => {
  const { seasonId } = useParams();
  const { season, teams, games } = useScheduler(seasonId);

  // Handle cases where `season` might not be available yet
  if (!season) {
    return (
      <Section>
        <SportContainer>
          <TitleContainer>
            <SportLandingTitle style={{ marginBottom: '2rem' }}>Loading Season...</SportLandingTitle>
          </TitleContainer>
        </SportContainer>
      </Section>
    );
  }

  // Calculate standings based on games
  const calculateStandings = (games) => {
    const standings = {};

    games.forEach(game => {
      const { team1_id, team2_id, score1, score2 } = game;

      if (score1 !== null && score2 !== null) {
        if (!standings[team1_id]) standings[team1_id] = { wins: 0, losses: 0, pointsFor: 0, pointsAgainst: 0 };
        if (!standings[team2_id]) standings[team2_id] = { wins: 0, losses: 0, pointsFor: 0, pointsAgainst: 0 };

        if (score1 > score2) {
          standings[team1_id].wins++;
          standings[team2_id].losses++;
        } else {
          standings[team1_id].losses++;
          standings[team2_id].wins++;
        }

        standings[team1_id].pointsFor += score1;
        standings[team1_id].pointsAgainst += score2;
        standings[team2_id].pointsFor += score2;
        standings[team2_id].pointsAgainst += score1;
      }
    });

    // Convert standings to an array and sort by wins
    const teamStandings = Object.keys(standings).map(teamId => ({
      teamId: parseInt(teamId, 10),
      ...standings[teamId]
    }));

    teamStandings.sort((a, b) => b.wins - a.wins);

    return teamStandings;
  };

  const standings = calculateStandings(games);

  return (
    <Section>
      <SportContainer>
        <TitleContainer>
          <SportLandingTitle style={{ marginBottom: '2rem' }}>
            {season.season_name} Basketball
          </SportLandingTitle>
        </TitleContainer>
        <SportName>Standings</SportName>
        <Standings standings={standings} teams={teams} /> {/* Pass standings and teams to the Standings component */}
        <SportName style={{ marginTop: '2rem' }}>Schedule</SportName>
        <Schedule seasonId={seasonId} />
      </SportContainer>
    </Section>
  );
};

export default League;