import React from 'react';
import { StandingsTable, StandingsHeader, StandingsRow, TeamCell, StatCell } from './Styles/Standings.styled';
import { FooterNoteText } from "../../../App.styled";

const Standings = ({ standings, teams }) => {
  // Utility to get team name by team ID
  const getTeamName = (teamId) => {
    const team = teams.find((t) => t.team_id === teamId);
    return team ? team.team_name : "Unknown Team";
  };

  return (
    <div>
      <StandingsTable>
        <thead>
          <StandingsRow>
            <StandingsHeader>Place</StandingsHeader>
            <StandingsHeader>Team</StandingsHeader>
            <StandingsHeader>W</StandingsHeader>
            <StandingsHeader>L</StandingsHeader>
          </StandingsRow>
        </thead>
        <tbody>
          {standings.map((team, index) => (
            <StandingsRow key={team.teamId}>
              <StatCell>{index + 1}</StatCell>
              <TeamCell>{getTeamName(team.teamId)}</TeamCell>
              <StatCell>{team.wins}</StatCell>
              <StatCell>{team.losses}</StatCell>
            </StandingsRow>
          ))}
        </tbody>
      </StandingsTable>
      <FooterNoteText>*Tiebreakers: Matchups, then points scored, then points against.</FooterNoteText>
    </div>
  );
};

export default Standings;